@import '../../../styles/colors.scss';

.detalles-cliente-fisico {
    padding-top: 24px;
    color: $grey-900;
    
    &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px;

        &-title {
            display: flex;
            gap: 16px;

            &-icon {
                width: 56px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $blue-50;
                border-radius: 8px;

                svg {
                    width: 24px;
                    height: 24px;
                    color: $blue-500;
                }
            }

            &-info {
                display: flex;
                flex-direction: column;

                span {
                    font-weight: 600;
                    font-size: 16px;
                }

                h6 {
                    font-weight: 800;
                    font-size: 26px;
                }
            }
        }

        &-info {
            display: flex;
            gap: 50px;

            &-title {
                font-size: 14px;
                font-weight: 600;
                color: $grey-300;
            }

            &-tipo {
                display: flex;
                flex-direction: column;

               &-value {
                   background-color: $blue-50;
                   padding: 4px 8px;
                   color: $blue-500;
                   font-weight: 800;
                   font-size: 12px;
                   border-radius: 8px;
               }
            }

            &-inspecciones {
                display: flex;
                flex-direction: column;

                div {
                    display: flex;
                    font-size: 14px;
                    font-weight: 600;
                    gap: 4px;

                    svg {
                        width: 20px;
                        height: 20px;
                        color: $grey-900;
                    }
                }
            }
        }
    }

    &-content {
        display: flex;
        gap: 24px;
        height: 75vh;

        &-left {
            flex: 0.5;
            display: flex;
            flex-direction: column;
            gap: 24px;
            max-width: 413px;

            &-bottom {
                padding: 24px 9px 24px 24px;
                background-color: $grey-50;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                max-height: 344px;
                overflow: auto;

                &-title {
                    font-size: 14px;
                    font-weight: 600;
                    color: $grey-500;
                }
            }
        }

        &-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    &-datagrid {
        padding: 24px;
        border-radius: 12px;
        background-color: $grey-50;
        height: 100%;

        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: $grey-500;
                font-weight: 600;
                font-size: 14px;
            }

            button {
                background-color: $blue-50;
                color: $blue-500;
                font-weight: 800;
                font-size: 12px;
                padding: 6px 12px 6px 16px;
                border: 0;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 10px;

                &:hover {
                    background-color: $blue-200;
                }
            }
        }
    }

    &-vinculados {
        display: flex;
        align-items: center;
        gap: 16px;

        &-icon {
            width: 44px;
            height: 44px;
            background-color: $blue-50;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: $blue-500;
                width: 20px;
                height: 20px;
            }
        }

        &-text {
            h6 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0 !important;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                color: $grey-500;
            }
        }
    }
}

.data-grid-bold-cell {
    font-weight: 800;
}
