.custom-circular-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-label {
        position: absolute;
        margin: 0;
        font-size: 12px;
        font-weight: 800;
        top: 22px;
        left: calc(50% + 2px);
        transform: translateX(-50%);
    }
}