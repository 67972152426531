@import '../../styles/colors.scss';

.selector-vencimiento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    width: 325px;
    border-radius: 20px;
    border: 1px solid $grey-300;
    background: #FFF;
    box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.08);
    padding-top: 20px;
    position: absolute;
    z-index: 99;
    right: 0;
    top: 20px;

    &-radios {
        padding: 0 24px 24px 24px;
    }
}

.selector-prevencimiento {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.selector-prevencimiento-label {
    margin: 0;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
}

.edit-vencimiento {
    width: 16px;
    height: 16px;
    background-color: $blue-50;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selector-vencimiento-main {
    position: relative;
}