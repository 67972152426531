@import '../../styles/colors.scss';

.modaleliminar {
    width: 411px;
    background-color: #ffffff;
    border-radius: 12px;
    max-height: 90vh;
    padding: 32px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 22px;
        font-weight:  800;
    }

    p {
        font-size: 12px;
        font-weight: 600;
        margin: 0;
    }

    &-actions {
        display: flex;
        align-items: center;
        gap: 24px;
    }
}