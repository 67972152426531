@import '../../styles/colors.scss';

.modeloconfig {
    margin-top: 42px;
    height: 70vh;

    &-header {
        margin-bottom: 43px;
    }

    &-tabs {
        margin-bottom: 23px;
    }

    &-container {
        height: 68vh;
        overflow: auto;
        display: flex;
        flex-direction: row;
    }

    &-modulo-container {
        flex: 1;
        overflow: scroll;
    }

    &-preview {
        &-container {
            flex: 0.6;
            background-color: $grey-50;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;

            .d-none {
                display: none;
            }

            img {
                width: 80%;
                height: 80%;
                filter: drop-shadow(0.15rem 0.20rem 0.25rem $grey-400);
            }

            svg {
                color: $grey-400;
                width: 30px;
                height: 30px;
            }
            p {
                text-overflow: ellipsis;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                padding-inline: 50px;
                text-align: center;
            }
        }
        &-close-icon {
            position: absolute;
            vertical-align: top;
            top: 12px;
            left: 12px;
            cursor: pointer;
        }
    }

    &-modulo {
        &-info {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                color: $grey-400;
            }
        }

        &::after {
            content: '';
            position: absolute;
            background-color: $grey-200;
            width: 100%;
            max-width: 470px;
            height: 1px;
            bottom: 0;
            left: 16px;
        }
    }

    &-modulo {
        max-width: 760px;
        position: relative;
        margin-bottom: 16px;

        &-title {
            font-size: 14px;
            font-weight: 800;
            margin: 0;
        }

        &-header {
            display: grid;
            grid-template-columns: 1.2fr 6fr 1fr 1fr 1fr;
            text-align: center;
            max-width: 738px;
            gap: 13px;

            p {
                margin: 0;
                font-size: 12px;
                color: $grey-400;
            }

            &-inferir {
                grid-column: 3;
            }

            &-preguntar {
                grid-column: 4;
            }

            &-previsualizar {
                grid-column: 5;
            }
        }
    }

    &-submodulos {
        display: grid;
        grid-template-columns: 0.5fr 6fr 1fr 1fr 1fr;
        min-height: 40px;
        align-items: center;
        justify-items: center;
        margin-bottom: 8px;

        &-add {
            max-width: 487px;
            padding-left: 21px;
            margin-bottom: 16px;
        }

        &-title {
            font-size: 14px;
            padding: 11px 8px;
            border-radius: 8px;
            border: 0.5px solid $grey-300;
            margin-right: 27px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            grid-column: 2;
        }

        &-tooltip {
            width: 100%;
            &-icon {
                color: $blue-500;
                width: 20px;
                height: 20px;
                vertical-align: text-top;
            }
        }

        &-orden {
            display: flex;
            flex-direction: column;
            margin-right: 14px;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        gap: 24px;
        max-width: 510px;
        justify-content: flex-end;
    }
}

.MuiAccordionSummary-root {
    max-width: 500px !important;
    justify-content: flex-start !important;
    min-height: 0 !important;
    margin-bottom: 16px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionSummary-content {
    margin: 0 !important;
}

.remove-line {
    &::after {
        content: '';
        position: absolute;
        background-color: $grey-200;
        width: 100%;
        max-width: 470px;
        height: 0;
        bottom: 0;
        left: 16px;
    }
}
