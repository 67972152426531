@import '../../styles/colors.scss';


.login {
    display: flex;
    align-items: center;

    .login-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 48px;
        align-items: center;
    
        &-header {
            max-width: 1140px;
            width: 100%;

            img {
                width: 120px;
            }
        }
    
        &-footer {
            background-color: #f5f6fa;
            padding: 16px;
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 0px;
            &-wrapper {
                width: 1140px;

                img {
                    width: 120px;
                    margin-bottom: 40px;
                }
        
                &-info {
                    &-social {
                        position: relative;
                        display: flex;
                        padding-bottom: 16px;
                        gap: 8px;
        
                        &-logo {
                            background-color: $blue-500;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
        
                            svg {
                                color: #ffffff;
                                width: 18px;
                                height: 18px;
                            }
                        }
        
                        &::after {
                            position: absolute;
                            content: "";
                            background-color: $blue-500;
                            width: 100%;
                            height: 2px;
                            bottom: 0;
                        }
                    }
        
                    &-links {
                        display: flex;
                        padding: 10px 0 0 0;
                        color: #073BCE;
        
                        ul {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            list-style: none;
                            gap: 16px;
                            font-weight: 500;
                            font-size: 14px;

                            a {
                                text-decoration: none;
                                color: #073BCE;
                            }
                        }
        
                        &-version {
                            margin-left: auto;
                            text-align: right;

                            span {
                                margin-left: auto;
                                font-weight: 600;
                                font-size: 14px;
                            }

                            .version {
                                color: $grey-300;
                                font-size: 8px;
                                align-self: center;
                            }
                        }
                    }
                }
            }
        }
    
        .form-input {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
        
            input {
                padding: 6px 16px;
                border: 1px solid #818a91;
                border-radius: 4px;
                font-family: "Nunito sans";
                font-size: 14px;
                color: $grey-900;
                height: 47px;
                width: 300px;
        
                &::placeholder {
                    color: $grey-400;
                    font-size: 14px;
                    font-weight: 400;
                }
        
                &:focus {
                    border-color: #687075;
                    outline: none;
                }
            }
        }
        
        .form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
}
}

.login-error {
    background-color: $blue-50;
    padding: 6px 12px;
    width: 300px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Nunito sans";
    color: $grey-900;
    border-radius: 4px;
}