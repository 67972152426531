@import '../../styles/colors.scss';

.progressbar {
    width: 100%;
    background-color: $blue-100;
    -webkit-appearance: none;
    accent-color: $blue-500;
    height: 4px;
    border-radius: 26px;
    border: 0;
}

.progressbar::-webkit-progress-bar {
    width: 100%;
    background-color: $blue-100;
    border-radius: 26px;
}

.progressbar::-webkit-progress-value {
    width: 100%;
    background-color: $blue-500;
    border-radius: 26px;
}


.progressbar::-moz-progress-bar {
    width: 100%;
    background-color: $blue-500;
    border-radius: 26px;
}