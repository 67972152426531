.paginated-card {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #EBEBF9;
    box-shadow: 8px 8px 16px 4px #898F960A;
    padding: 30px;
    width: max-content;
    height: fit-content;

    &-pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
}
