@import '../../styles/colors.scss';

.add-button {
    all: unset;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $blue-50;
    border-radius: 8px;
    height: 46px;
    color: $grey-300;

    p {
        margin: 0;
        font-size: 14px;
    }
}