@import '../../styles/colors.scss';

.error-message {
    padding: 13px 16px;
    background-color: #FEF3F4;
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.50);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
        margin: 0;
        padding: 0;
    }

    &-title {
        font-size: 14px;
        font-weight: 600;
    }

     &-subtitle {
        font-size: 12px;
        color: $grey-500;
     }
}