@import '../../styles/colors.scss';

.abriendo-pdf {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $grey-900;

    p {
        margin: 0;
    }

    &-msg1 {
        margin: 0;
        font-family: "Nunito sans";
        font-size: 16px;
        font-weight: 800;
    }

    &-msg2 {
        margin: 0;
        font-family: "Nunito sans";
        font-size: 14px;
        font-weight: 400;
    }

    &:focus-visible {
        outline: 0;
    }
}