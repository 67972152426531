@import '../../styles/colors.scss';

.menu {
    border: 1px solid $grey-100;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px 1px $grey-200;
    border-radius: 6px;
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;

    &-item {
        font-size: 12px;
        font-weight: 800;
        padding: 6px 24px;
        color: $grey-500;
        cursor: pointer;

        &:hover {
            color: $blue-500;
        }
    }
}