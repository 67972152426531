@import '../../styles/colors.scss';

// Grid

.MuiDataGrid-root {
    border: 0 !important;
    font-family: 'Nunito sans' !important;

}

.MuiDataGrid-row {
    background-color: #ffffff;
    border-radius: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    transform: scale(0.99);

    &:hover {
        box-shadow: 1px 1px 8px 1px #d9d7e1;
    }
}

.MuiDataGrid-cell {
    border: 0 !important;
    color: $grey-900;
    font-weight: 600;
    font-size: 14px;
    padding: 0 16px !important;
}

.MuiDataGrid-columnHeaders {
    border: 0 !important;
    color: $grey-300;
}

.MuiDataGrid-filterIcon, .MuiDataGrid-sortIcon {
    color: $grey-300 !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 6px !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
    cursor: default;
}


.MuiDataGrid-columnSeparator {
    display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered {
    background-color: #ffffff !important;
}

// Pagination

.MuiPaginationItem-root.Mui-selected {
    background: $blue-100 !important;
    color: $blue-500 !important;
    border-radius: 8px;
    font-weight: 800;
    padding: 6px 8px !important;
}

.MuiPaginationItem-root {
    color: $grey-300 !important;
    min-width: 24px !important;
    height: 24px !important;
    font-family: 'Nunito sans' !important;
    font-size: 12px !important;
}

// Filters

.toolbar {
    margin-bottom: 20px;
    justify-content: flex-end;
    gap: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
        position: absolute;
        content: "";
        background-color: $grey-100;
        height: 1px;
        width: 100%;
        bottom: -4px;
        left: 0;
    }
}

.toggle-button-filters {
    height: 28px;
    border: 0 !important;
    border-radius: 8px !important;
    font-family: 'Nunito sans' !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: $grey-300 !important;
    margin: 0 8px !important;
    width: max-content;

    &:hover {
        background-color: $grey-100 !important;
        color: $grey-500 !important;
    }

    svg {
        display: none;
    }
}

.toggle-button-filters.Mui-selected {
    color: $blue-500 !important;
    padding: 6px 12px 6px 8px !important;

    &:first-of-type {
        padding: 6px 12px !important;
    }
    
    span {
        margin-top: 1px;
    }

    &:hover {
        background-color: $blue-200 !important;
        color: $blue-700 !important;
    }

    svg {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
}

.MuiOutlinedInput-root {
    border-radius: 8px !important;
}

.filter-label {
    margin-bottom: 4px;
}


// Boton Crear Nuevo X

.new-button {
    border: 0;
    border-radius: 12px;
    color: #ffffff;
    background: $blue-500;
    padding: 11px 24px;
    font-weight: 800;

    &:hover {
        background: $blue-700;
    }
}

.highlight-row {
    animation: bordercolor 10s;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

@keyframes bordercolor {
    0% {
        outline: 2px solid rgba(185, 208, 253, 0);
        outline-style: auto;
        box-shadow: 1px 1px 8px 1px rgba(217, 215, 225, 0);
    }
    
    50% {
        outline: 2px solid rgba(185, 208, 253, 1);
        outline-style: auto;
        box-shadow: 1px 1px 8px 1px rgba(217, 215, 225, 1);
    }

    100% {
        outline: 2px solid rgba(185, 208, 253, 0);
        outline-style: auto;
        box-shadow: 1px 1px 8px 1px rgba(217, 215, 225, 0);
    }
}

.no-rows {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datagrid-rows-with-error {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FDF6F0;
    border: 1px solid #FDC38D;
    padding: 12px 8px;
    margin-top: 40px;
    max-height: 254px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-100;
        margin-block: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-300;
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;
            gap: 8px;
    
            h1 {
                color: #EB760A;
                font-size: 16px;
                font-weight: 800;
                margin: 0;
            }
    
            svg {
                color: #EB760A;
                width: 20px;
                height: 20px;
            }
        }

        button {
            background: none;
            border: 0;

            svg {
                width: 25px;
                height: 25px;
                color: $grey-400;
            }
        }
    }

    p {
        margin: 0;
        margin-left: 30px;
    }

    &-rows {
        margin-left: 30px;

        ul {
            padding: 0;
        }
    }

    &-show-more {
        color: $blue-500;
        font-weight: 600;
        cursor: pointer;
    }
}

.actionCell {
    overflow: visible !important;
}

.estado-vencida-grid {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #FCEFF5;

    svg {
        color: #B53572;
        width: 16px;
        height: 16px;
    }
}

.estado-prevencida-grid {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #FEE5E6;

    svg {
        color: #E54B54;
        width: 16px;
        height: 16px;
    }
}