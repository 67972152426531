@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

.configheader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
        display: flex;
        align-items: center;
        gap: 8px;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;

            svg {
                width: 35px;
                height: 35px;
            }
        }

        &-title {
            margin: 0;
            font-size: 26px;
            font-weight: 800;
        }

        &-edit {
            all: unset;
            width: 24px;
            height: 24px;
            color: $grey-400;
        }

        &-delete {
            all: unset;
            width: 24px;
            height: 24px;
            color: $error;
        }

        &-input {
            border-radius: 12px;
            border: 1px solid $blue-500;
            max-width: 314px;
            padding: 10px 16px;
            font-size-adjust: 26px;
            font-weight: 800;

            &:focus {
                border-radius: 12px;
                outline: 1px solid $blue-500;
            }
        }
    }

    &-right {
        display: flex;
        align-items: center;
        gap: 16px;

        &-fecha {
            font-size: 12px;
            color: $grey-400;
            margin: 0;
        }

        &-enlace {
            all: unset;
            display: flex;
            align-items: center;
            gap: 8px;
            color: $blue-500;
            font-size: 12px;
            font-weight: 600;

            svg {
                width: 24px;
                height: 24px;
                color: $blue-500;
            }
        }

        &-enlace-copiado {
            all: unset;
            display: flex;
            align-items: center;
            gap: 8px;
            color: $blue-700;
            font-size: 12px;
            font-weight: 600;

            svg {
                width: 12px;
                height: 12px;
                color: $blue-700;

                path {
                    stroke-width: 2px;
                }
            }
        }
    }
}