@import '../../styles/colors.scss';

.dropdownmenu-container {
    position: relative;

    .dropdownmenu-button {
        background-color: #ffffff;
        border: 1px solid $grey-300;
        border-radius: 12px;
        display: flex;
        align-items: center;
        color: $grey-500;
        font-weight: 800;
        font-size: 14px;
        height: 40px;
        gap: 8px;

        &-active {
            background-color: $grey-100;
            border-color: 1px solid $grey-300;
        }
    
        &:hover {
            background-color: $grey-50;
            border-color: $grey-400;
        }
    }
    
    .dropdownmenu-content {
        background-color: #ffffff;
        border: 1px solid $grey-100;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 1px 1px 8px 1px $grey-200;
        position: absolute;
        top: 56px;
        z-index: 1050;
        display: none;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        right: 0;

        &-left {
            top: 30px;
            right: 0;
            padding: 8px 0;
            gap: 0;
            width: 170px;

            ul {
                list-style: none;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
                padding: 0;
                padding-left: 12px;
                
                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: $grey-500;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                }
            }
        }

        &-right {
            left: 0;
        }

       /*  &-item {
            display: flex;
            flex-direction: column;
            width: 100%;
        } */
    }
    
    .show {
        display: flex;
    }
}