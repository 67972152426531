@import '../../styles/colors.scss';

.lista-notas {
    background-color: $grey-50;
    border-radius: 12px;
    width: 100%;
    padding: 24px;

    &-main {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 155px;
        overflow: auto;
    }

    &-header {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: $grey-500;
    }

    &-actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 16.5px;
    }
}