@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

@mixin flexible ($direction: 'column', $wrap: 'nowrap', $align: 'center', $justify: 'center') {
    display: flex;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

.nuevo-ramo-modal {
    background-color: #ffffff;
    width: 381px;
    padding: 32px 34px;
    border-radius: 12px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;

    &-steps {
        position: absolute;
        top: 27px;
        right: 30px;
        font-size: 14px;
        color: $grey-500;

        span {
            font-weight: 800;
        }
    }

    &-title {
        @include flexible(row, nowrap, center, space-between); 
        h3 {
            font-size: 22px;
            font-weight: 800;
            margin: 0;
        }
    
        span {
            font-size: 14px;
            font-weight: 400;
            color: $grey-500;

            &::first-letter {
                font-weight: 800;
            }
        }
    }
    
    &-section {
        &-title {
            display: flex;
            gap: 6px;
        }

        h6 {
            font-weight: 800;
            font-size: 14px;
            margin-bottom: 16px;
        }

        input {
            padding: 11px 16px;
            border: 1px solid $grey-100;
            border-radius: 12px;
            font-size: 14px;
            color: #171717;
            font-weight: 600;
            width: 100%;
            height: 40px;

            &:hover {
                border: 1px solid $grey-300;
            }

            &:focus{
                outline: none;
                box-shadow: 0 0 0 1pt $blue-500;
            }

            &::placeholder {
                color: $grey-300;
            }


            &:disabled {
                background-color: #F7F7F9;
                color: #171717;
                border: none;
            }

            &:disabled:hover {
                border: none;
            }
        }
    }

    &-types {
        @include flexible(column, nowrap, space-between, center);

        &-list {
            @include flexible(row, wrap, start, space-between);
            margin-top: 16px;
        }

    }

    &-custom-types {
        @include flexible(column, nowrap, space-between, center);

        &-list {
            @include flexible(row, wrap, center, space-between);
            gap: 20px;
            margin-top: 16px;
            height: 306px;
            overflow: auto;
            padding-right: 16px;
        }

    }

    &-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
    }
}

.ramo-button {
    all: unset;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    &.active {
        .ramo-button-logo {
            background-color: $blue-50;
            border: 1px solid $blue-200;
        }

        .ramo-button-icon {
            background-color: $blue-50;
            border: 1px solid $blue-200;

            svg {
                path {
                    fill: $blue-500;
                }
            }

            &-exception {
                svg {
                    path {
                        stroke: $blue-500;
                        fill: transparent;
                    }
                }
            }

            .icono-vida {
                stroke: $blue-500;
                fill: transparent
            }
        }

        .ramo-button-title {
            color: $blue-500;
        }
    }

    &.disabled {
        .ramo-button-logo {
            background-color: white;
            border: 1px solid $grey-100;
        }

        .ramo-button-icon {
            background-color: white;
            border: 1px solid $grey-100;
        }

        .ramo-button-title {
            color: $grey-400;
        }
    }

    &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        border-radius: 5px;
        border: 1px solid black;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        border-radius: 5px;
        border: 1px solid black;

        svg {
            width: 40px;
            height: 40px;

            path {
                fill: $grey-300;
            }
        }

        &-exception {
            svg {
                path {
                    stroke: $grey-300;
                    fill: transparent;
                }
            }
        }

        .icono-vida {
            stroke: $grey-300;
            fill: transparent
        }
    }

    &-title {
        margin-top: 7px;
        font-size: 12px;
    }
}

.big-nuevo-ramo {
    all: unset;
    width: 413px;
    height: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-500;
    background-color: $blue-50;
    border-radius: 20px;

    svg {
        width: 52px;
        height: 52px;
    }
}

.ramos-maestros {
    display: flex;
    flex-direction: column;

    &-data {
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        &-titulo {
            font-size: 12px;
            font-weight: 600;
            color: $grey-400;
            margin: 0;

            &-activo {
                color: $blue-500;
            }
        }

        p {
            margin: 0;
            font-size: 12px;
            color: $grey-400;
        }

        &-sugerencias {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;

            li {
                padding: 4px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $grey-50;
                border-radius: 8px;
                color: $grey-400;
                font-size: 10px;
            }
        }
    }
}