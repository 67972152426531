@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

.nuevo-cliente-modal {
    background-color: #ffffff;
    width: 568px;
    padding: 48px;
    border-radius: 12px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 90vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-100;
        margin-block: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-300;
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 22px;
            font-weight: 800;
            margin: 0;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $grey-500;

            &::first-letter {
                font-weight: 800;
            }
        }
    }

    &-radio {
        display: flex;
        flex-direction: row !important;
        gap: 24px;
    }

    &-input {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-label {
            font-size: 14px;
            font-weight: 400;
            color: $grey-500;
            display: flex;
            gap: 4px;

            svg {
                width: 16px;
                height: 16px;
                color: $blue-500;
            }
        }

        input {
            padding: 11px 16px;
            border: 1px solid $grey-100;
            border-radius: 12px;
            font-size: 14px;
            color: #171717;
            font-weight: 600;
            height: 40px;

            &:hover {
                border: 1px solid $grey-300;
            }

            &:focus{
                outline: none;
                box-shadow: 0 0 0 1pt $blue-500;
            }

            &::placeholder {
                color: $grey-300;
            }


            &:disabled {
                background-color: #F7F7F9;
                color: #171717;
                border: none;
            }

            &:disabled:hover {
                border: none;
            }
        }

        &-warning-message {
            color: $error;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            gap: 2px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &-error {
            border: 1px solid $error !important;
        }
    }

    &-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        margin-top: 36px;
    }

    &-data {
        font-weight: 400;
        font-size: 14px;

        div {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        svg {
            width: 16px;
            height: 16px;
        }

        span {
            font-weight: 800;
        }
    }

    &-contactos {
        &-title {
            display: flex;
            gap: 4px;
            align-items: center;
            margin-bottom: 16px;

            h6 {
                font-weight: 800;
                font-size: 16px;
                margin: 0;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                color: $grey-300;
            }
        }
    }
}

.modal-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;

    svg {
        width: 20px;
        height: 20px;
        color: $blue-500;
        vertical-align: text-bottom;
    }

    &-text {
        color: $grey-900;
        display: flex;
        align-items: center;
        gap: 2px;

        svg {
            color: $grey-900;
        }
    }
}

.MuiTooltip-tooltip {
    opacity: 1 !important;
    background-color: $blue-800 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    font-family: 'Nunito sans' !important;
    border-radius: 6px !important;
}

.MuiTooltip-arrow {
    &::before {
        opacity: 1 !important;
        background-color: $blue-800 !important;
    }
}

.nuevo-cliente-modal-input-caracteristica {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 4px;

    .MuiAutocomplete-root {
        position: absolute;
        height: 37px !important;

        .MuiAutocomplete-input {
            height: 37px !important;
            padding: 0 0 0 16px !important;
        }
    }
}

.modal-input-phone {
    width: 100%;
    padding-left: 90px !important;
}