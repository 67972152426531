.dashboard {
    &-toolbar {
        display: flex;
        gap: 12px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;
        
        &-filters {
            display: flex;
            align-items: center;
            gap: 21px;
        }

        &-date {
            p {
                color: #635F76;
                font-size: 14px;
            }
        }

        &-archivadas {
            all: unset;
            background-color: #EBF1FE;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: #0046CF;
            font-weight: 800;
            font-size: 14px;
            cursor: pointer;

            &-icon {
                height: 16px;
                width: 16px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    height: 100%;
                    color: #0046CF;
                }
            }
        }
    }

    &-main {
        display: flex;
        gap: 24px;
    
        &-col1 {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    
        &-col2 {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}

.dashboard-card {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #EBEBF9;
    box-shadow: 8px 8px 16px 4px #898F960A;
    padding: 25px;

    &-title {
        margin: 0;
        font-weight: 800;
        font-size: 16px;
    }
}

.consumo-leads {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 525px;
    height: 294px;

    &-data {
        display: flex;
        align-items: center;
    }
        
    &-chart {
        width: 250px;
        position: relative;

        &-info {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 50%;
            transform: translateY(-50%);
            left: 70px;

            h2 {
                margin: 0;
                font-weight: 800;
                font-size: 16px;
            }

            p {
                margin: 0;
                font-size: 12px;
                color: #89869B
            }
        }
    }

    &-leyenda {
        display: flex;
        flex-direction: column;
        gap: 6px;

        &-item {
            display: flex;
            gap: 20px;
            align-items: center;
            text-decoration: none;
            color: #171717;

            svg {
                width: 32px;
                height: 32px;
            }

            &-info {
                display: flex;
                flex-direction: column;

                p {
                    margin: 0;
                    font-size: 12px;
                }

                &-count {
                    font-weight: 800;
                }
            }
        }

        &-encurso {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 4px;
                border-radius: 8px;
                background-color: #E4B305;
                left: 40px;
            }
        }
        
        &-aira0 {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 4px;
                border-radius: 8px;
                background-color: #0046CF;
                left: 40px;
            }
        }

        &-completados {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 4px;
                border-radius: 8px;
                background-color: #53BD8A;
                left: 40px;
            }
        }

        &-enviados {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 4px;
                border-radius: 8px;
                background-color: #E79142;
                left: 40px;
            }
        }

        &-gestionados {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 4px;
                border-radius: 8px;
                background-color: #3BA1AE;
                left: 40px;
            }
        }
    }
}

.tiempos-uso {
    display: flex;
    flex-direction: column;
    width: 525px;
    height: 192px;
    gap: 10px;

    &-container {
        display: flex;
        align-items: center;
        gap: 40px;
    }

    &-promedio {
        display: flex;
        flex: 7;
        gap: 24px;
        align-items: center;

        p {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            width: 80px;
        }

        &-valor {
            margin: 0;
            font-size: 12px;
            font-weight: 800;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &-numero {
                position: absolute;
                width: 62px;
                height: 62px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .MuiChartsAxis-line, .MuiChartsAxis-tick {
        display: none;
    }
    .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
        display: none;
    }
}

.dashboard-ramos{
    display: flex;
    width: 370px;
    flex-direction: column;
    gap: 25px;
    min-height: 360px;

    &-container {
        display: flex;
        justify-content: space-between;
        width: 280px;
        align-items: flex-end;
        
        &-mas {
            display: flex;
            align-items: center;
            gap: 4px;

            a {
                text-decoration: none;
                display: flex;
                gap: 4px;
                align-items: center;
            }

            p {
                margin: 0;
                color: #0046CF;
                font-weight: 800;
                font-size: 12px;
                white-space: nowrap;
            }

            svg {
                color: #0046CF;
                font-weight: 800;
                width: 16px;
                height: 16px;
                stroke-width: 3;
            }
        }
    }

    &-item {
        display: flex;
        gap: 10px;
        align-items: end;

        &-icono {
            width: 46px;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #EBF1FE;
            border-radius: 8px;
            border: 1px solid #B9D0FD;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &-data {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 4px;

            &-nombre {
                margin: 0;
                color: #0046CF;
                font-weight: 800;
                font-size: 12px;
            }

            &-cantidad {
                margin: 0;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

.conversion-leads {
    width: 100%;
    height: 262px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: fit-content;

    &-title {
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
            color: #89869B;
            font-size: 12px;
        }
    }
    
    &-item {
        position: relative;
        display: flex;
        gap: 20px;
        align-items: center;

        &-text {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
        }

        &-first {
            &::after {
                content: '';
                position: absolute;
                background-color: #EBEAF0;
                width: 100%;
                height: 1px;
                bottom: -14px;
            }
        }
    }
}

.dashboard-usuarios {
    width: 463px;

    &-main {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 104px;
    }

    &-item {
        display: grid;
        grid-template-columns: 149px 48px 40px;
        align-content: center;
        gap: 40px;

        &-icon {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: #B0ADBE;
                height: 100%;
            }
        }

        &-nombre {
            display: flex;
            gap: 4px;
            align-items: center;

            p {
                margin: 0;
                font-weight: 600;
                font-size: 12px;
                text-overflow: ellipsis;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        &-tipo {
            font-size: 10px;
            font-weight: 800;
            margin: 0;
            color: #0046CF;
            background-color: #EBF1FE;
            border-radius: 8px;
            padding: 4px 8px;
            width: 52px;
            text-align: center;
        }

        &-inspecciones {
            display: flex;
            align-items: center;
            gap: 4px;

            p {
                margin: 0;
                font-weight: 600;
                font-size: 14;
            }
        }
    }
}

.distribucion-leads {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #EBEBF9;
    box-shadow: 8px 8px 16px 4px #898F960A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    width: 690px;
    height: 100%;

    &-tags {
        position: absolute;
        display: flex;
        gap: 16px;
        bottom: 10px;
        z-index: 999;
        left: 16px;

        p {
            margin: 0;
            background-color: #ffffff;
            color: #0046CF;
            font-weight: 800;
            font-size: 12px;
            padding: 6px 12px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &-main {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        &-title {
            margin: 0;
            font-weight: 800;
            font-size: 16px;
        }

        &-input {
            width: 80%;
            background-color: #EBF1FE;
            border: 0;
            outline: 0;
            box-shadow: none;
        }
    }    
}

.dashboard-empty-state {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
        font-size: 14px;
        color: #B0ADBE;
        font-weight: 600;
    }

    svg {
        height: 100%;
    }
}