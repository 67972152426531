@import '../../styles/colors.scss';

.google-map {
    position: relative;

    &-marker {
        position: absolute;
        width: 35px;
        height: 60px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    &-coords {
        font-size: 9px;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 2px;
        color: black;
        background-color: white;
        padding: 1px 2px;
        font-weight: 800;
    }
}

.custom-input {
    position: relative;
    display: flex;
    flex-direction: column;

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        align-self: center;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }
    }

    .input-error {
        border: 1px solid $error;

        &:focus {
            box-shadow: 0 0 0 1px $error;
            outline: none;
            border-color: transparent;
        }
    }

    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 14px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,9px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 1px) scale(0.75);
    }

    .filled {
        transform: translate(0, 1px) scale(0.75);
        font-size: 13px;
    }
}

.input-mapa {
    margin-bottom: 16px;

    &-button {
        all: unset;
        position: absolute;
        right: 7px;
        cursor: pointer;
        background-color: $blue-50;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;

        h3 {
            font-size: 12px;
            margin: 0;
        }

        svg {
            width: 22px;
            height: 22px;
            
            path {
                stroke: $blue-500;
            }
        }

        &:disabled {
            background-color: $grey-50;

            svg {                
                path {
                    stroke: $grey-300;
                }
            }
        }
    }
}