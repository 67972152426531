.MuiPickersToolbar-root  {
    display: none !important;
}

.MuiDialogActions-root {
    display: none !important;
}

.MuiDateCalendar-root {
    max-height: 325px !important;
}