@import '../../styles/colors.scss';

.navbar-main {
    background-color: #ffffff;
    height: 100vh;
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    width: 72px;
    position: fixed;
    transition: width 200ms ease;
    z-index: 1301;
    border-right: 1px solid $grey-100;

    &:last-child {
        color: $grey-300;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-top: 36px;
        flex: 2;
        width: 56px;
        transition: width 150ms ease;

        li {
            margin-bottom: 1rem;
            
            svg {
                position: fixed;
            }

            a {
                text-decoration: none;
                display: flex;
                gap: 15px;
                padding: 16px;
                color: $grey-300;
                font-weight: 800;

                &:hover {
                    color: $blue-400;
                }
            }

            .navbar-link-text {
                transform: translateX(-100%);
                transition: all 200ms ease;
                opacity: 0;
                padding-left: 40px;
            }

            .active {
                color: $blue-500;
                background-color: $blue-50;
                border-radius: 16px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    background-color: $blue-500;
                    width: 4px;
                    border-radius: 4px;
                    height: 24px;
                }
            }
        }
    }
}

.navbar-main:hover {
    width: 196px;
}

.navbar-main:hover ul{
    width: 180px;
}

.navbar-main:hover .navbar-link-text{
    transform: translateX(0%);
    opacity: 1;
}

.navbar-main:hover .navbar-link-text {
    display: block;
}

.navbar-logo {
    padding-left: 16px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 28px;

    span {
        color: $grey-300;
    }

    svg {
        width: 23px;
    }
}

.version {
    color: $grey-300;
    font-size: 8px;
    align-self: center;
}

.navbar-settings {
    display: flex;
    align-self: flex-start;
    justify-self: flex-end;
    gap: 15px;
    padding-left: 16px;
    color: $grey-300;
    font-weight: 800;
    cursor: pointer;

    span {
        transform: translateX(-100%);
        transition: all 200ms;
        opacity: 0;
    }

    &:hover {
        color: $blue-400;
    }
}

.navbar-main:hover .navbar-settings span {
    transform: translateX(0%);
    opacity: 1;
}