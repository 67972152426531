@import '../../../styles/colors.scss';

.nuevo-airacero {
    border: 2px solid $blue-500;
    border-radius: 12px;
    color: $blue-500;
    background: white;
    padding: 11px 24px;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 4px;

    &-text {
        font-size: 12px;
        margin-top: 16px;
    }

    &-no-movil {
        display: flex;
        align-items: baseline;
        gap: 8px;
        margin-bottom: 16px;

        &-icon {
            svg {
                color: $blue-500;
                width: 16px;
                height: 16px;
            }    
        }
        
        p {
            margin: 0;
            color: $blue-500;
            font-size: 12px;
            font-weight: 600;
        }
    }

    &-input {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            font-size: 12px;
            color: $grey-500;
        }
    }

    &-prueba-checkbox {
        display: flex;
        gap: 4px;
        align-items: center;
        padding-top: 1rem;
        font-size: 14px;
        color: $blue-500;
        font-weight: 800;

        p {
            margin: 0;
            padding: 0;
        }
    }
}

.nuevo-airacero-modal {
    width: 580px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 48px;
    max-height: 90vh;
    overflow-y: auto;

    h5 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 32px;
    }

    h6 {
        font-weight: 800;
    }

    &-tooltip {
        display: flex;
        align-items: baseline;
        gap: 10px;

        & > div svg {
            color: $blue-500;
            width: 20px;
            height: 20px;
            vertical-align: text-top;
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-100;
        margin-block: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-300;
    }
}

.nuevo-airacero-mt {
    margin-top: 48px;
}

.toggle-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.toggle-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 145px;
    overflow: auto;
}

.toggle-button-inspeccion {
    border: 0 !important;
    border-radius: 8px !important;
    font-family: 'Nunito sans' !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: $grey-200 !important;
    display: flex;
    flex-direction: column;
    padding: 15px 8px;
    width: 152px;
    height: 80px !important;

    p {
        max-width: 18ch;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
    }

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: $grey-200;
        }
    }

    &-exception {
        svg {
            path {
                stroke: $grey-200;
                fill: transparent;
            }
        }
    }

    .icono-vida {
        stroke: $grey-100;
        fill: transparent
    }

    &:hover {
        background-color: $grey-100 !important;
        color: $grey-500 !important;
    }

    &:disabled {
        background-color: $grey-50;
    }
}

.toggle-button-inspeccion.Mui-selected {
    background-color: $blue-50 !important;
    color: $blue-500 !important;

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: $blue-500;
        }
    }

    .icono-vida {
        stroke: $blue-500;
        fill: transparent
    }

    &:hover {
        background-color: $blue-200 !important;
        color: $blue-700 !important;
    }
}

.toggle-button-inspeccion-exception.Mui-selected {
    svg {
        path {
            stroke: $blue-500;
            fill: transparent;
        }
    }
}

.inspeccion-select {
    height: 40px;
}

.title-and-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    margin-bottom: 4px;
}

.text-blue {
    color: #0046CF;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
}
.text-value {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.text-bold {
    width: 472px;
    height: 18px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #635F76;
}

.new-client-button {
    border: 0;
    background-color: transparent;
    color: $blue-500;
    font-weight: 800;
    padding: 8px 24px;
}

.nuevo-airacero-select {
    & .MuiSelect-select {
        font-family: 'Nunito sans' !important;
        color: $grey-900 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    svg {
        width: 16px;
        height: 16px;
        color: $grey-500;
    }
}

.nuevo-airacero-radio {
    flex-direction: row !important;
    gap: 10px;

    .MuiTypography-root {
        font-family: 'Nunito sans' !important;
        font-weight: 400 !important;
        font-size: 14px !important;
    }

    input {
        padding: 11px 16px;
        width: 100%;
        border-radius: 12px;
        color: $grey-900;
        font-weight: 600;
        border: 1px solid $grey-100;
        font-size: 14px;

        &:disabled {
            background-color: $grey-50;
            border: none;
        }

        &:placeholder {
            font-weight: 600;
            color: $grey-300;
        }

    }
}

.nuevo-airacero-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 48px;

    button {
        padding: 11px 24px;
        border-radius: 12px;
        font-weight: 800;
        font-size: 14px;
    }

    &_cancelar {
        border: 1px solid $grey-300;
        background-color: #ffffff;
        color: $grey-500;
    }

    &_crear {
        border: 0;
        background-color: $blue-500;
        color: #ffffff;
    }
}

.nuevo-cliente {
    width: 568px;
    max-height: 816px;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 48px;

    h5 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 32px;
    }

    h6 {
        font-weight: 800;
    }

    input {
        padding: 11px 16px;
        border: 1px solid $grey-100;
        border-radius: 12px;
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        color: #171717;

        &::placeholder {
            font-weight: 600;
            font-size: 14px;
            color: $grey-300;
        }
    }

    .nuevo-airacero-radio {
        margin-bottom: 32px;
    }

    &-inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.MuiList-root {
    font-family: 'Nunito sans' !important;

    .MuiMenuItem-root {
        font-family: 'Nunito sans' !important;
        font-size: 14px !important;
        font-weight: 400;
        color: $grey-500;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
            color: $grey-500;
        }

        &:hover {
            font-weight: 800;
        }

        &:active {
            color: $blue-500;
            font-weight: 800;

            svg {
                color: $blue-500;
            }
        }
    }
}

.MuiTooltip-tooltip {
    opacity: 1 !important;
    background-color: $blue-800 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    font-family: 'Nunito sans' !important;
    border-radius: 6px !important;
}

.MuiTooltip-arrow {
    &::before {
        opacity: 1 !important;
        background-color: $blue-800 !important;
    }
}

.MuiOutlinedInput-root > svg {
    width: 20px;
    height: 20px;
    stroke-width: 3px;
    color: $grey-500;
    margin-right: 15px;
}

input#clientes-combo-box {
    font-family: 'Nunito sans';
    margin-left: 34px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

input#caracteristicas-combo-box {
    font-family: 'Nunito sans';
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;

}

input#direccion-combo-box {
    font-family: 'Nunito sans';
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

input{

    &:disabled{
        background-color: #F7F7F9;
        -webkit-text-fill-color: $grey-900 !important;
    }
}

#cliente-icon {
    color: $grey-900;
    stroke-width: 2px;
    width: 16px;
    height: 16px;
}

input:disabled.div#cliente-icon{
        color: #D9D7E1 !important;
}

#autocomplete-option-icon {
    width: 16px;
    height: 16px;
    color: $grey-500;
    margin-right: 8px;
}

.direccion-options {
    display: flex;
    gap: 8px;
    align-items: center;
}

.direccion-input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 16px;
    margin-top: 8px;
}

.dropdown-input {
    position: relative;

    .chevron-down {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        transition: all 200ms ease-in-out;
    }

    &-container {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }

        &:focus + .chevron-down {
            transform: rotate(180deg);
        }
    }

    &-calle {
        padding: 4px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 100%;
        height: 48px;
        font-weight: 600;
        font-size: 14px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }

        &:focus + .chevron-down {
            transform: rotate(180deg);
        }
    }

    .input-error {
        border: 1px solid $error;
    }
    
    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 16px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,13px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 5px) scale(0.75);
    }

    .filled {
        transform: translate(0, 5px) scale(0.75);
    }
}

// togglebuttons

.toggle-buttons-white {
    min-height: 64px;
    padding: 16px;
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.5) !important;
    display: flex;
    justify-content: space-between !important;
    border-radius: 8px !important;
    width: 100% !important;
    background-color: white;
    border: 0 !important;
    font-family: 'Nunito sans' !important;
}

.toggle-buttons {
    min-height: 64px;
    padding: 16px;
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.5) !important;
    display: flex;
    justify-content: space-between !important;
    border-radius: 8px;
    width: 100% !important;
    background-color: $blue-50 !important;
    border: 0 !important;
    font-family: 'Nunito sans' !important;

    &-text {
        display: flex;
        flex-direction: column;
        text-transform: none !important;
        text-align: left !important;

        &-primary {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: #171717;
            font-family: 'Nunito sans' !important;
            text-transform: capitalize;
        }

        &-secondary {
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: $grey-500;
            font-family: 'Nunito sans' !important;
        }
    }

    &-checkbox {
        display: flex;
        align-items: center;
        gap: 8px;
        pointer-events: none;

        label {
            width: 20px;
            height: 20px;
        }

        input[type="checkbox"] {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        &-icon {
            display: inline-block;
            height: 20px;
            width: 20px;
            background-color: $grey-100;
            border: 0;
            border-radius: 50%;
            padding: 4px;
        
            &-active {
                background-color: $blue-500;
                border: 0;
            }
        }
    }
}

.toggle-buttons-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
}

.toggle-buttons-list-personas {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    max-height: 500px;
    overflow: auto;
    padding: 10px;
}

.button-mapa {
    background-color: transparent;
    border: 0;
    display: flex;
    gap: 4px;
    margin-top: 24px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    
    span {
        color: $blue-500;
        font-weight: 800;
        font-size: 14px;
    }

    svg {
        width: 16px;
        height: 16px;
        color: $blue-500;
    }
}

.map-container {
    input {
        padding: 11px 16px;
        border: 1px solid $grey-100;
        border-radius: 12px;
        font-size: 14px;
        color: #171717;
        font-weight: 600;
        height: 40px;
        width: 100%;

        &:hover {
            border: 1px solid $grey-300;
        }

        &:focus{
            outline: none;
            box-shadow: 0 0 0 1pt $blue-500;
        }

        &::placeholder {
            color: $grey-300;
        }


        &:disabled {
            background-color: #F7F7F9;
            color: #171717;
            border: none;
        }

        &:disabled:hover {
            border: none;
        }
    }
}

.sin-resultados {
    color: #b10000;
    font-weight: 800;
    font-size: 14px;
}

.inputs-nombre {
    display: flex;
    gap: 8px;
}

.texto-personas {
    font-size: 14px;
}