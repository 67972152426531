@import '../../styles/colors.scss';

.details-card {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: fit-content;
    background-color: #f7f7f9;
    border-radius: 12px;
    position: relative;

    &-title {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        color: $grey-500;
        font-weight: 800;
        font-size: 16px;
    }

    &-edit {
        width: 32px;
        height: 32px;
        background-color: $blue-50;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;

        svg {
            color: $blue-500;
        }

        &:hover {
            background-color: $blue-200;
        }
    }

    &-items {
        gap: 16px;
        white-space: nowrap;
        grid-auto-flow: column;
    }

    &-row {
        flex-direction: column;

        &-content {
            color: $grey-900;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 4px;

            &-icon {
                color: $grey-500;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            span {
                white-space: normal;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &-label {
            color: $grey-300;
            font-weight: 400;
            font-size: 12px;
        }
    }

    &-actions {
        position: absolute;
        bottom: 24px;
        right: 24px;
/*         button {
            background-color: #ffffff;
            padding: 11px 24px;
            border: 1px solid $grey-300;
            border-radius: 12px;
            color: $grey-500;
            font-weight: 800;
            font-size: 14px;

            &:hover {
                background-color: $grey-50;
                border: 1px solid $grey-400;
            }

            &:focus {
                background-color: $grey-100;
                border: 1px solid $grey-300;
            }
        } */
    }
}