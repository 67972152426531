@import '../../styles/colors.scss';

.header {
    display: flex;
    color: $grey-300;
    justify-content: space-between;
    align-items: center;

    &-logo {
        height: 40px;
    }

    &-right {
        display: flex;
        align-items: center;
        gap: 24px;

        &-user {
            position: relative;
    
            & > svg:hover {
                 path {
                    stroke: $blue-400;
                 }
                 
                 circle {
                    stroke: $blue-400;
                 }
            }
    
            & > svg:active {
                path {
                   stroke: $blue-500;
                }
                
                circle {
                   stroke: $blue-500;
                }
           }
    
            &-menu {
                position: absolute;
                z-index: 99;
                right: 0;
                top: 35px;
            }
        }
    
        svg, img {
            cursor: pointer;
        }
    }
}
.videolink {
text-decoration: none;
}
.boton-demo {
    align-items: center;
    
    background: #fff;
    border: 2px solid #0046cf;
    border-radius: 12px;
    border-color: #0046cf;
    color: #0046cf;
    display: flex;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    padding: 11px 24px;
    list-style: none;
    
}


.guia {
    border: none;
    color:#959DA5;
    background: transparent;
}

.boton-demo:hover {
  background-color: #e4ebf8;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.boton-demo:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}
