.home {
    padding-top: 24px;
    
    &-title {
        font-size: 26px;
        font-weight: 800;
    }

    &-construction {
        padding-top: 210px;
    }
}