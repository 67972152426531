@import './colors.scss';

.primary-button {
    padding: 11px 24px;
    background-color: $blue-500;
    color: #ffffff;
    border-radius: 12px;
    border: 0;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:hover {
        background-color: $blue-700;
    }

    &:active {
        background-color: $blue-800;
    }

    &:disabled {
        background-color: $grey-200;
    }
}

.secondary-button {
    padding: 11px 24px;
    background-color: #ffffff;
    border: 1px solid $grey-300;
    border-radius: 12px;
    font-weight: 800;
    font-size: 14px;
    color: $grey-500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:hover {
        border: 1px solid $grey-400;
    }

    &:active {
        background-color: $grey-100;
    }

    &:disabled {
        color: $grey-300;
        border: 1px solid $grey-200;
    }
}

.secondary-button-withIcon {
    padding: 11px 24px;
    background-color: #ffffff;
    border: 1px solid $grey-300;
    border-radius: 12px;
    font-weight: 800;
    font-size: 14px;
    color: $grey-500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 8px;

    svg {
        color: $grey-500 !important;
        width: 18px !important;
        height: 18px !important;
        stroke-width: 2px;
    }

    &:hover {
        border: 1px solid $grey-400;
    }

    &:active {
        background-color: $grey-100;
    }

    &:disabled {
        color: $grey-300;
        border: 1px solid $grey-200;
    }
}

.tertiary-button {
    padding: 11px 24px;
    background-color: $blue-50;
    border-radius: 12px;
    font-weight: 800;
    font-size: 14px;
    color: $blue-500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    gap: 8px;
    white-space: nowrap;

    &:hover {
        background-color: $blue-200;
    }

    &:active {
        background-color: $blue-300;
    }

    &:disabled {
        color: $grey-300;
        background-color: $grey-50;
    }
}

.tertiary-button-withIcon {
    padding: 6px 12px 6px 16px;
    background-color: $blue-50;
    border-radius: 12px;
    font-weight: 800;
    font-size: 14px;
    color: $blue-500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    gap: 8px;
    white-space: nowrap;

    &:hover {
        background-color: $blue-200;
    }

    &:active {
        background-color: $blue-300;
    }

    &:disabled {
        color: $grey-300;
        background-color: $grey-50;
    }
}

.ghost-primary-withIcon {
    padding: 8px 16px 8px 24px;
    background-color: transparent;
    border: 0;
    border-radius: 12px;
    color: $blue-500;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    svg {
        color: $blue-500 !important;
        width: 24px !important;
        height: 24px !important;
    }

    &:hover {
        background-color: $blue-50;
    }

    &:active {
        background-color: $blue-200;
    }

    &:disabled {
        color: $grey-300;
    }
}

.text-with-icon {
    padding-top: 1rem;
    background-color: transparent;
    border: 0;
    border-radius: 12px;
    color: $blue-500;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    svg {
        color: $blue-500 !important;
        width: 24px !important;
        height: 24px !important;
    }

    &:disabled {
        color: $grey-300;
    }
}

.hide {
    display: none !important;
}

.delete-button {
    padding: 11px 24px;
    background-color: $error;
    color: #ffffff;
    border-radius: 12px;
    border: 0;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:hover {
        background-color: #cf434a;
    }

    &:active {
        background-color: #b1383e;
    }

    &:disabled {
        background-color: $grey-200;
    }
}

.option-button {
    padding: 6px 12px 6px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid $grey-400;
    background-color: transparent;
    border-radius: 8px;
    color: $grey-500;
    font-size: 12px;
    font-weight: 800;
    height: 40px;
    width: 100%;
    justify-content: center;

    svg {
        color: $grey-500;
        width: 16px;
        height: 16px;
    }
}

.option-button-active {
    padding: 6px 12px 6px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid $blue-500;
    border-radius: 8px;
    color: $blue-500;
    background-color: $blue-50;
    font-size: 12px;
    font-weight: 800;
    height: 40px;
    width: 100%;
    justify-content: center;

    svg {
        color: $blue-500;
        width: 16px;
        height: 16px;
    }
}

.reset-button-styles {
    all: unset;
}