@import '../../styles/colors.scss';

.modal-confirmation {
    background-color: #ffffff;
    padding: 48px;
    border-radius: 12px;
    width: 568px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
        font-weight: 800;
        font-size: 22px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        color: $grey-500;
        line-height: 18px;
        margin: 0;
    }

    &-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        margin-top: 32px;
    }
}