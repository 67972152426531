.loader {
    background-color: #FFFFFF;
    opacity: 80%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-gif {
        width: 100px;
        height: 100px;
    }
}