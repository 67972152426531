@import '../../styles/colors.scss';

.textarea {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
        font-size: 14px;
        font-weight: 800;
    }

    p {
        font-size: 14px;
    }

    textarea {
        width: 100%;
        height: auto;
        resize: none;
        border-radius: 12px;
        border: 1px solid $grey-200;
        align-self: center;
        padding: 8px 16px;
        height: 40px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &::placeholder {
            font-weight: 600;
            font-size: 14px;
            color: $grey-300;
        }
    }

    &-chars {
        align-self: flex-end;
        color: $grey-300;
        font-weight: 600;
        font-size: 14px;
    }
}