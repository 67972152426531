@import '../../styles/colors.scss';

.inspection-card {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: fit-content;
    border-radius: 12px;
    position: relative;

    &-title {
        display: flex;
        gap: 16px;
        
        span {
            color: $grey-500;
            font-weight: 800;
            font-size: 14px;
        }
    }

    &-items {
        gap: 16px;
        white-space: nowrap;
    }

    &-row {
        display: flex;
        flex-direction: column;

        &-content {
            color: $grey-900;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;

            svg {
                width: 16px;
                height: 16px;
            }

            &-text {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-width: 200px;
                font-weight: 400;
                color: #171717;
                font-size: 14px;
                white-space: pre-wrap;
            }
        }

        &-label {
            color: $grey-300;
            font-weight: 400;
            font-size: 12px;
        }
    }

    &-actions {
        button {
            background-color: #ffffff;
            padding: 11px 24px;
            border: 1px solid $grey-300;
            border-radius: 12px;
            color: $grey-500;
            font-weight: 800;
            font-size: 14px;

            &:hover {
                background-color: $grey-50;
                border: 1px solid $grey-400;
            }

            &:focus {
                background-color: $grey-100;
                border: 1px solid $grey-300;
            }
        }
    }
}

.long-text-alignment {
    align-items: baseline;

    svg {
        padding-top: 2px;
    }
}