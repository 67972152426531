@import './styles/colors.scss';
@import './styles/buttons.scss';

@font-face {
    font-family: 'Nunito sans';
    src: local('Nunito sans'), url(./assets/fonts/NunitoSans-Regular.woff) format(truetype);
}
@font-face {
    font-family: 'Nunito sans';
    src: local('Nunito sans'), url(./assets/fonts/NunitoSans-SemiBold.woff) format(truetype);
    font-weight: 600;
}
@font-face {
    font-family: 'Nunito sans';
    src: local('Nunito sans'), url(./assets/fonts/NunitoSans-Bold.woff) format(truetype);
    font-weight: 700;
}
@font-face {
    font-family: 'Nunito sans';
    src: local('Nunito sans'), url(./assets/fonts/NunitoSans-ExtraBold.woff) format(truetype);
    font-weight: 800;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito sans', sans-serif !important;
}

body {
    font-family: 'Nunito sans', sans-serif !important;
    color: $grey-900;
}

.flex-container {
    width: 100%;
    display: flex;
    min-height: 100vh;
}

.routes-container {
    padding: 24px 40px;
    width: 100%;
    margin-left: 72px;
    min-height: 100vh;
}

.modal-input {
    border-radius: 12px;
    height: 40px;
}

.mtop-16 {
    margin-top: 16px !important;
}

.mtop-8 {
    margin-top: 8px;
}

.fs-14 {
    font-size: 14px;
}

.grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px 16px;
}

.span-2 {
    grid-column: span 2;
}

.text-input {
        padding: 11px 16px;
        border: 1px solid $grey-100;
        border-radius: 12px;
        font-size: 14px;
        color: #171717;
        font-weight: 600;
        height: 40px;
        width: 100%;

        &:hover {
            border: 1px solid $grey-300;
        }

        &:focus{
            outline: none;
            box-shadow: 0 0 0 1pt $blue-500;
        }

        &::placeholder {
            color: $grey-300;
        }


        &:disabled {
            background-color: #F7F7F9;
            color: #B0ADBE;
            border: none;
        }

        &:disabled:hover {
            border: none;
        }
}

.cell-direccion {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    white-space: normal !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $grey-300 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $blue-500 !important;
}

.MuiOutlinedInput-root.Mui-disabled {
    background-color: $grey-50 !important;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid $grey-50 !important;
}

.MuiRadio-root {   
    svg:first-of-type {
        path {
            fill: $grey-300;
        }
    }

    svg {
        path {
            fill: $blue-500;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.leaflet-container {
    height: 598px;
    width: 723px;
}