@import '../../../styles/colors.scss';

.detalles-inspeccion {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-header {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 34px;
        margin-bottom: 8px;

        &-icon {
            background-color: $blue-50;
            border-radius: 8px;
            height: 56px;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 37px;
                height: 37px;
                color: $blue-500;
            }
        }

        &-data {
            display: flex;
            align-items: flex-end;
            width: 100%;
            gap: 16px;
        }

        &-title {
            color: $grey-900;

            span {
                font-size: 16px;
                font-weight: 600;
            }

            div {
                font-size: 26px;
                font-weight: 800;
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }

        &-estado-enviada {
            color: #eb760a;
            border: 2px solid #fdf6f0;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #eb760a;
                width: 20px;
                height: 20px;
            }
        }

        &-estado-completada {
            color: #53bd8a;
            border: 2px solid #e7fcf2;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;

            svg {
                color: #53bd8a;
                width: 20px;
                height: 20px;
            }
        }

        &-estado-en-curso {
            color: #e4b305;
            border: 2px solid #fdf7e1;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #e4b305;
                width: 20px;
                height: 20px;
            }
        }

        &-estado-procesando {
            color: #635f76;
            border: 2px solid #d9d7e1;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #635f76;
                width: 20px;
                height: 20px;
            }
        }
        
        &-estado-vencida {
            color: #B53572;
            border: 2px solid #FCEFF5;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #B53572;
                width: 20px;
                height: 20px;
            }
        }

        &-estado-gestionado {
            color: #3BA1AE;
            border: 2px solid #E3F6F9;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #3BA1AE;
                width: 20px;
                height: 20px;
            }
        }

        &-estado-prevencida {
            color: #E54B54;
            border: 2px solid #FCEFF5;
            border-radius: 8px;
            padding: 6px 12px 6px 8px;
            font-weight: 800 !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
            gap: 4px;
            

            svg {
                color: #E54B54;
                width: 20px;
                height: 20px;
            }
        }


        &-fechas {
            margin-left: auto;
            font-size: 14px;
            font-weight: 600;
            color: $grey-500;
            display: flex;
            gap: 16px;
            align-items: center;

            span {
                color: $grey-300;
                font-size: 12px;
                font-weight: 600;
            }

            svg {
                width: 20px;
                height: 20px;
            }

            &-align-icon {
                display: flex;
                align-items: center;
                gap: 4px;
            }

            button {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
    }

    &-cards {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 24px;

        &-left {
            flex: 2;
        }

        &-right {
            flex: 1;
        }
    }

    &-version {
        width: 100%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }

        &-content {
            height: 356px;
            background-color: $grey-50;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $grey-500;
            font-size: 14px;

            &-empty {
                display: flex;
                flex-direction: column;
                width: 225px;
                text-align: center;
                align-items: center;
            }
        }
    }

    &-download-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-pdf {
        padding: 24px;
        background-color: $grey-50;
        display: flex;
        border-radius: 12px;
        flex-direction: column;
        gap: 24px;

        &-titulo {
            font-size: 14px;
            font-weight: 600;
            color: $grey-500;
        }

        &-data {
            display: flex;
            justify-content: space-between;

            &-item {
                display: flex;
                flex-direction: column;
                gap: 4px;

                &-title {
                    font-size: 12px;
                    font-weight: 600;
                    color: $grey-300;
                }

                &-content {
                    display: flex;
                    gap: 4px;
                    align-items: center;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            button {
                align-self: flex-end;
            }
        }
    }
}

.boton {
    display: flex;
    align-items: center;

    &-enlace {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            font-size: 13px;
            font-weight: 600;
            color: $blue-500;
        }

        svg {
            width: 24px;
            height: 24px;
            color: $blue-500;
        }
    }

    &-enlace-copiado {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            color: $blue-700;
            font-size: 13px;
            font-weight: 600;
        }

        svg {
            width: 12px;
            height: 12px;
            color: $blue-700;

            path {
                stroke-width: 2px;
            }
        }
    }
}

.pdf-state-disponible {
    color: $blue-500;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 4px;
}

.pdf-state-proceso {
    color: $grey-500;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 4px;
}

.pdf-date {
    font-size: 14px;

    svg {
        color: $grey-500;
    }
}

.pdf-proceso {
    display: flex;
    flex-direction: row;
    color: $blue-500;
    background-color: $blue-50;
    padding: 8px 48px 8px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    max-width: 472px;
    gap: 8px;
}

.anexos-button {
    border: 0;
    background-color: transparent;
    display: flex;
    gap: 8px;
    text-decoration: none;
    cursor: pointer;

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: $grey-500;
            width: 16px;
            height: 16px;
        }
    }

    span {
        color: $grey-500;
        font-size: 14px;
    }
}

.asignar-cliente-title {
    font-size: 14px;
    font-weight: 600;
    color: #635f76;
    margin: 0;
}

.phone-input {
    width: 100%;
    position: relative;
    
    .MuiAutocomplete-root {
        position: absolute;
        top: 8px;
    }

    &-input {
        width: 100%;
        border-radius: 12px;
        height: 40px;
        padding-left: 16px;
        margin-top: 8px;
        border: 1px solid $grey-100;
    }

    &-input-otro {
        width: 100%;
        border-radius: 12px;
        height: 40px;
        padding-left: 100px;
        margin-top: 8px;
        border: 1px solid $grey-100;
    }
}

.dropdown-menu-item {
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    padding: 11px 0;

    p {
        margin: 0;
        font-size: 14px;
        color: $grey-500;
        font-weight: 400;
    }

    svg {
        width: 23px;
        height: 23px;
        color: $grey-500;
    }
}
