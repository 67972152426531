@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

@mixin flexible ($direction: 'column', $wrap: 'nowrap', $align: 'center', $justify: 'center') {
    display: flex;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

.modelos-sidebar {
    @include flexible(column, nowrap, flex-start, flex-start);
    position: absolute;
    top: 0;
    right: 0;
    width: 448px;
    height: 100%;
    background-color: white;;
    padding: 20px;
    box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.20);
    
    &-close {
        @include flexible(row, nowrap, flex-end, flex-end);
        width: 100%;
        margin: -10px -10px 20px 5px;
    }

    &-header {
        @include flexible(row, nowrap, flex-start, space-between);
        width: 100%;
        margin-bottom: 40px;
    }

    &-search {
        width: 100%;
        margin-bottom: 40px;

        &-input {
            width: 100%;
            padding: 5px 10px;
        }
    }

    &-model-list {
        width: 100%;
        overflow: auto;
    }

    &-actions {
        display: flex;
        gap: 20px;
        width:  408px;
        justify-content: flex-end;
        position: fixed;
        bottom: 20px;
    }
}

.model-summary {
    width: 100%;
    border-bottom: 1px solid $grey-100;
    margin: 25px 0;
    padding: 5px 0;
    cursor: pointer;

    &:hover {
        background-color: #EBF1FE;
        border-radius: 12px;
    }

    &-state {
        font-size: 12px;
        font-weight: 800;
        width: fit-content;
        color: #0046CF;
        background-color: #EBF1FE;
        border-radius: 8px;
        padding: 2px 4px 2px 4px;
        margin-bottom: 8px;
    }

    &-title {
        @include flexible(column, nowrap, flex-start, space-between);
        padding-left: 5px;
        font-weight: 800;
        font-size: 16px;
        text-transform: capitalize;
    }

    &-footer {
        @include flexible(row, nowrap, center, space-between);
    }

    &-date {
        @include flexible(column, nowrap, center, space-between);
        padding-left: 5px;
        font-size: 12px;
        font-weight: 600;
    }

    &-link {
        @include flexible(row, nowrap, center, space-between);
        font-size: 12px;
        font-weight: 600;
        color: $blue-500;
        gap: 10px;
        cursor: pointer;
    }
}

.sidebar-logo {
    @include flexible(row, nowrap, center, space-between);
    gap: 10px;

    &-image {

        svg {
            width: 30px;
            height: 30px;
        }
    }

    &-title {
        font-size: 22px;
        font-weight: 800;
        max-width: 16ch;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.modelos-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 520px;
    justify-content: center;

    p {
        font-size: 14px;
        margin: 0;
    }
}