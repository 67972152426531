$blue-900: #00163F;
$blue-800: #011F59;
$blue-700: #00308E;
$blue-500: #0046CF;
$blue-400: #4A88FF;
$blue-300: #86AFFF;
$blue-200: #B9D0FD;
$blue-100: #D0DFFB;
$blue-50: #EBF1FE;

$grey-900: #171717;
$grey-800: #24232A;
$grey-700: #403D4B;
$grey-500: #635F76;
$grey-400: #89869B;
$grey-300: #B0ADBE;
$grey-200: #D9D7E1;
$grey-100: #EBEAF0;
$grey-50: #F7F7F9;

$grey-200-blur: rgb(217,215,225,0.9);

$success: #53BD8A;
$warning: #E4B305;
$error: #E64B54;