@import '../../styles/colors.scss';

.nueva-inspeccion {
    border: 0;
    border-radius: 12px;
    color: #ffffff;
    background: $blue-500;
    padding: 11px 24px;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover {
        background: $blue-700;
    }

    &-no-movil {
        display: flex;
        align-items: baseline;
        gap: 8px;
        margin-bottom: 16px;

        &-icon {
            svg {
                color: $blue-500;
                width: 16px;
                height: 16px;
            }    
        }
        
        p {
            margin: 0;
            color: $blue-500;
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.nueva-inspeccion-modal {
    width: 580px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 48px;
    max-height: 90vh;
    overflow-y: auto;

    h5 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 32px;
    }

    h6 {
        font-weight: 800;
    }

    &-tooltip {
        display: flex;
        align-items: baseline;
        gap: 10px;

        & > div svg {
            color: $blue-500;
            width: 20px;
            height: 20px;
            vertical-align: text-top;
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-100;
        margin-block: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        border: solid 3px transparent;
        box-shadow: inset 0 0 10px 10px $grey-300;
    }
}

.nueva-inspeccion-mt {
    margin-top: 48px;
}

.toggle-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 145px;
    overflow: auto;
}

.toggle-button-inspeccion {
    border: 0 !important;
    border-radius: 8px !important;
    font-family: 'Nunito sans' !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: $grey-200 !important;
    display: flex;
    flex-direction: column;
    padding: 15px 8px;
    width: 152px;
    height: 80px !important;

    p {
        max-width: 18ch;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
    }

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: $grey-200;
        }
    }

    &-exception {
        svg {
            path {
                stroke: $grey-200;
                fill: transparent;
            }
        }
    }

    .icono-vida {
        stroke: $grey-100;
        fill: transparent
    }

    &:hover {
        background-color: $grey-100 !important;
        color: $grey-500 !important;
    }

    &:disabled {
        background-color: $grey-50;
    }
}

.toggle-button-inspeccion.Mui-selected {
    background-color: $blue-50 !important;
    color: $blue-500 !important;

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: $blue-500;
        }
    }

    .icono-vida {
        stroke: $blue-500;
        fill: transparent
    }

    &:hover {
        background-color: $blue-200 !important;
        color: $blue-700 !important;
    }
}

.toggle-button-inspeccion-exception.Mui-selected {
    svg {
        path {
            stroke: $blue-500;
            fill: transparent;
        }
    }
}

.inspeccion-select {
    height: 40px;
}

.title-and-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    margin-bottom: 4px;
}

.new-client-button {
    border: 0;
    background-color: transparent;
    color: $blue-500;
    font-weight: 800;
    padding: 8px 24px;
}

.nueva-inspeccion-select {
    & .MuiSelect-select {
        font-family: 'Nunito sans' !important;
        color: $grey-900 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    svg {
        width: 16px;
        height: 16px;
        color: $grey-500;
    }
}

.nueva-inspeccion-radio {
    flex-direction: row !important;
    gap: 10px;
    flex-wrap: nowrap !important;

    .MuiTypography-root {
        font-family: 'Nunito sans' !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        white-space: nowrap !important;
    }

    .MuiFormControlLabel-root {
        margin-right: 0 !important;
    }

    input {
        padding: 11px 16px;
        width: 100%;
        border-radius: 12px;
        color: $grey-900;
        font-weight: 600;
        border: 1px solid $grey-100;
        font-size: 14px;

        &:disabled {
            background-color: $grey-50;
            border: none;
        }

        &:placeholder {
            font-weight: 600;
            color: $grey-300;
        }

    }
}

.nueva-inspeccion-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 48px;

    button {
        padding: 11px 24px;
        border-radius: 12px;
        font-weight: 800;
        font-size: 14px;
    }

    &_cancelar {
        border: 1px solid $grey-300;
        background-color: #ffffff;
        color: $grey-500;
    }

    &_crear {
        border: 0;
        background-color: $blue-500;
        color: #ffffff;
    }
}

.nuevo-cliente {
    width: 568px;
    max-height: 816px;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 48px;

    h5 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 32px;
    }

    h6 {
        font-weight: 800;
    }

    input {
        padding: 11px 16px;
        border: 1px solid $grey-100;
        border-radius: 12px;
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        color: #171717;

        &::placeholder {
            font-weight: 600;
            font-size: 14px;
            color: $grey-300;
        }
    }

    .nueva-inspeccion-radio {
        margin-bottom: 32px;
    }

    &-inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.MuiList-root {
    font-family: 'Nunito sans' !important;

    .MuiMenuItem-root {
        font-family: 'Nunito sans' !important;
        font-size: 14px !important;
        font-weight: 400;
        color: $grey-500;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
            color: $grey-500;
        }

        &:hover {
            font-weight: 800;
        }

        &:active {
            color: $blue-500;
            font-weight: 800;

            svg {
                color: $blue-500;
            }
        }
    }
}

.MuiTooltip-tooltip {
    opacity: 1 !important;
    background-color: $blue-800 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    font-family: 'Nunito sans' !important;
    border-radius: 6px !important;
}

.MuiTooltip-arrow {
    &::before {
        opacity: 1 !important;
        background-color: $blue-800 !important;
    }
}

.MuiOutlinedInput-root > svg {
    width: 20px;
    height: 20px;
    stroke-width: 3px;
    color: $grey-500;
    margin-right: 15px;
}

input#clientes-combo-box {
    font-family: 'Nunito sans';
    margin-left: 34px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

input#caracteristicas-combo-box {
    font-family: 'Nunito sans';
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;

}

input#modelo-combo-box {
    font-family: 'Nunito sans';
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

input{

    &:disabled{
        background-color: #F7F7F9;
        -webkit-text-fill-color: $grey-900 !important;
    }
}

#cliente-icon {
    color: $grey-900;
    stroke-width: 2px;
    width: 16px;
    height: 16px;
}

input:disabled.div#cliente-icon{
        color: #D9D7E1 !important;
}

#autocomplete-option-icon {
    width: 16px;
    height: 16px;
    color: $grey-500;
    margin-right: 8px;
}

.phone-input {
    width: 100%;
    position: relative;
    
    .MuiAutocomplete-root {
        position: absolute;
        top: 8px;
    }

    &-input {
        width: 100%;
        border-radius: 12px;
        height: 40px;
        padding-left: 16px;
        margin-top: 8px;
        border: 1px solid $grey-100;
    }

    &-input-otro {
        width: 100%;
        border-radius: 12px;
        height: 40px;
        padding-left: 100px;
        margin-top: 8px;
        border: 1px solid $grey-100;
    }
}