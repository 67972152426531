@import '../../styles/colors.scss';

.modelos {
    padding-top: 24px;
    
    &-title-bar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    &-title {
        font-size: 26px;
        font-weight: 800;

        &-actions {
            display: flex;
            align-items: center;
            gap: 17px;
        }
    }

    &-construction {
        display: flex;
        flex-flow: row wrap;
        padding-top: 50px;
        gap: 40px;
        height: 80vh;
        overflow: auto;
    }
}

.boton-azul {
    align-items: center;
    
    background: #0046cf;
    border: 2px solid #0046cf;
    border-radius: 12px;
    border-color: #0046cf;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    padding: 11px 24px;
    list-style: none;
    
}

.modelos-creados {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 448px;
    height: 100%;
    background-color: white;;
}