@import '../../styles/colors.scss';

.adjunto-agente {
    &-header {
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
            color: $grey-500;
            width: 16px;
            height: 16px;
        }

        p {
            color: $grey-300;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
        }
    }

    &-lista {
        display: flex;
        flex-direction: column;
        gap: 9px;
        margin-top: 10px;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;

        p {
            margin: 0;
            font-size: 14px;
            color: $blue-500;
            font-weight: 800;
        }


        &-actions {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            &-download {
                color: $blue-500;
            }

            &-delete {
                color: $error;
            }
        }
    }
}