@import '../../styles/colors.scss';

.search-filter {
    position: relative;
    margin-right: auto;;
}

.search-filter-input {
    padding: 10px 16px 10px 45px;
    border: 1px solid $grey-100;
    border-radius: 12px;
    outline: none;
    font-size: 14px !important;
    font-weight: 600;
    width: 310px;

    &::placeholder {
        color: $grey-300;
    }

    &.grey50 {
        background-color: $grey-50;
        width: 100%;
    }
}

.search-filter-icon {
    position: absolute;
    top: 9px;
    left: 14px;
    color: $grey-300;
}