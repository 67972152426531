@import '../../styles/colors.scss';

.modal-tos {
    background-color: #ffffff;
    max-width: 800px;
    padding: 48px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 90vh;
    overflow-y: auto;

    p {
        margin: 0;
    }
    
    &-logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background: $grey-300;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -40px;
        }
    }

    &-text {
        height: 70%;
        overflow-y: auto;
        margin-top: 60px;

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-track {
            border-radius: 100vw;
            border: solid 3px transparent;
            box-shadow: inset 0 0 10px 10px $grey-100;
            margin-block: .5rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 100vw;
            border: solid 3px transparent;
            box-shadow: inset 0 0 10px 10px $grey-300;
        }
    }

    &-actions {
        display: flex;
        flex-direction: column;
        gap: 40px;

        button {
            align-self: flex-end;
        }

        &-check {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                font-size: 14px;
            }
        }
    }
}