@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

.modal-carga {
    background-color: #ffffff;
    width: 568px;
    padding: 48px;
    border-radius: 12px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 90vh;
    overflow-y: auto;

    p {
        margin: 0;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &-title {
            margin: 0;
            font-size: 22px;
            font-weight: 800;
        }

        &-steps {
            font-size: 14px;
            color: $grey-500;

            span {
                font-weight: 800;
            }
        }
    }

    &-subtitle {
        margin: 0;
        font-size: 16px;
        font-weight: 800;
    }

    &-archivo-subido {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 89px;

        &-data {
            display: flex;
            gap: 6.5px;

            svg, span {
                color: $grey-500;
                font-weight: 800;
            }
        }

        &-actions {
            display: flex;
            gap: 7px;

            &-edit {
                color: $blue-500;
                cursor: pointer;
            }
    
            &-delete {
                color: $error;
                cursor: pointer;
            }
        }
    }

    &-file-selection {
        p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: $grey-500;
        }

        &-subtitle {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 16px;
        }

        &-fila {
            margin-top: 24px;

            p {
                margin-bottom: 4px;
            }
        }
    }

    &-columns {
        &-data {
            display: flex;
            flex-direction: column;
            gap: 4px;

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: $grey-500;
            }
        }

        &-inputs {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;

            span {
                text-align: left;
                font-size: 14px;
                font-weight: 600;
                color: $grey-500;
            }

            &-item {
                display: grid;
                grid-template-columns: 352px 104px;
                justify-content: space-between;
                align-items: center;

                h3 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 800;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: $grey-300;
                }

                input {
                    padding: 11px 16px;
                    border: 1px solid $grey-100;
                    border-radius: 12px;
                    outline: none;
                    font-size: 14px !important;
                    font-weight: 600;
                    height: 40px;
                    text-transform: uppercase;
                
                    &::placeholder {
                        color: $grey-300;
                        text-transform: none;
                    }
                }
            }
        }
    }

    &-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        margin-top: 48px;
    }

    &-cancelar {
        p {
            font-size: 14px;
            color: $grey-500;
            margin-top: 16px;
        }
    }

    &-progress {
        &-text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 25px;
            margin-bottom: 32px;
        }

        p {
            font-size: 16px;
        }
    }

    &-success {
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            svg {
                color: $grey-400;
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        &-text {
            margin: 32px 0;

            p {
                margin-top: 8px;
            }
        }
    }

    &-error {
        display: flex;
        flex-direction: column;
        background-color: #FEF3F4;
        border-radius: 8px;
        padding: 8px;
        margin-top: 24px;
        
        h1 {
            margin-bottom: 24px;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $error !important;
            font-weight: 600 !important;
            font-size: 14px;
            margin: 0;
        }
    }

    &-errormsg {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 8px;
        gap: 32px;
        
        h1 {
            margin-bottom: 8px;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $error !important;
            font-weight: 600 !important;
            font-size: 14px;
            margin: 0;
        }

        &-actions {
            display: flex;
            gap: 16px;
            justify-content: flex-end;
        }
    }
}


input#row-selection-combo-box {
    font-family: 'Nunito sans';
    padding-left: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

.MuiOutlinedInput-root {
    padding: 1px !important;
    border-radius: 12px !important;
    height: 40px;

    &:focus {
        outline: none;
        border: 0;
    }
}

.MuiAutocomplete-option {
    font-family: 'Nunito sans' !important;
    color: $grey-500;
    font-size: 14px;

    &:hover {
        font-weight: 800;
    }

    &:active {
        color: $blue-500;
    }
}

.MuiAutocomplete-endAdornment {
    margin-right: 6px;
    margin-top: 2px;
}
