.model-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 20px;
    width: 413px;
    height: 254px;
    background-color: white;
    
    &-inactive {
        color: #B0ADBE !important;
        background-color: #F6F5F8 !important;
    } 

    &-state {
        justify-content: flex-end;
        font-size: 14px;
    }

    &-content {
        display: flex;
        flex: 1;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 26px;
        font-weight: 800;

        &-logo {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            width: fit-content;

            &-title {
                margin-top: 10px;
                font-size: 16px;
                max-width: 20ch;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            svg {
                width: 48px;
                height: 48px;
            }
        }
    }

    &-footer {
        display: flex;
        flex-flow: row nowrap;
        height: 56px;
        justify-content: space-between;
        align-items: center;

        &-info {
            display: flex;
            flex: 1;
            align-items: flex-start;
            justify-content: center;
            flex-flow: column nowrap;
            font-size: 12px;

            &-amount-models {
                font-weight: 600;
            }

            &-last-modified {
                color: #89869B;
            }
        }

        &-action {
            display: flex;
            font-size: 14px;
            font-weight: 800;
            align-items: center;
            padding: 0 16px 0 24px;
            color: #0046CF;
            cursor: pointer;
        }
    }


    &-state {
        display: flex;
        justify-content: flex-end;

        &-content {
            width: fit-content;
            color: #0046CF;
            background-color: #EBF1FE;
            border-radius: 8px;
            padding: 4px 8px 4px 8px;
        }
    }
}



.separator {
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    margin: 10px 0 10px 0;
    background-color: lightgray;
}


// svg {
//     path {
//         fill: red;
//     }
// }