@import '../../styles/colors.scss';

.tabs-container {
    display: flex;
    gap: 12px;
    align-items: center;
    position: relative;
    padding-left: 12px;

    &::after {
        position: absolute;
        content: "";
        background-color: $grey-100;
        height: 1px;
        width: 100%;
        bottom: -12px;
        left: 0;
    }

    &-tab {
        all: unset;
        padding: 0 12px;
        color: $grey-500;
        font-size: 16px;
        position: relative;
    }

    &-tab-active {
        all: unset;
        color: $grey-900;
        font-weight: 800;
        font-size: 16px;
        position: relative;
        padding: 0 12px;

        &::after {
            content: "";
            position: absolute;
            background-color: $blue-500;
            height: 4px;
            border-radius: 4px;
            width: 100%;
            bottom: -12px;
            left: 0;
        }
    }
}