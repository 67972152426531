@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

@mixin flexible ($direction: 'column', $wrap: 'nowrap', $align: 'center', $justify: 'center') {
    display: flex;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

.nuevo-modelo-modal {
    background-color: #ffffff;
    width: 381px;
    padding: 32px 34px;
    border-radius: 12px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 90vh;
    overflow-y: auto;

    &-title {
        @include flexible(row, nowrap, center, space-between); 
        h3 {
            font-size: 22px;
            font-weight: 800;
            margin: 0;
        }
    
        span {
            font-size: 14px;
            font-weight: 400;
            color: $grey-500;

            &::first-letter {
                font-weight: 800;
            }
        }
    }
    
    &-section {
        h6 {
            font-weight: 800;
            font-size: 14px;
            margin-bottom: 16px;
        }

        input {
            padding: 11px 16px;
            border: 1px solid $grey-100;
            border-radius: 12px;
            font-size: 14px;
            color: #171717;
            font-weight: 600;
            width: 100%;
            height: 40px;

            &:hover {
                border: 1px solid $grey-300;
            }

            &:focus{
                outline: none;
                box-shadow: 0 0 0 1pt $blue-500;
            }

            &::placeholder {
                color: $grey-300;
            }


            &:disabled {
                background-color: #F7F7F9;
                color: #171717;
                border: none;
            }

            &:disabled:hover {
                border: none;
            }
        }
    }

    &-types {
        @include flexible(column, nowrap, space-between, center);

        &-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin-top: 16px;
            max-height: 275px;
            overflow: auto;
            padding-right: 10px;
        }

    }

    &-actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
    }
}

.modelo-button {
    all: unset;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    &.active {
        .modelo-button-logo {
            background-color: $blue-50;
            border: 1px solid $blue-200;
        }

        .modelo-button-title {
            color: $blue-500;
        }
    }

    &.disabled {
        .modelo-button-logo {
            background-color: white;
            border: 1px solid $grey-100;

            svg {
                width: 40px;
                height: 40px;
    
                path {
                    fill: $grey-300;
                }
            }
    
            &-exception {
                svg {
                    path {
                        stroke: $grey-300;
                        fill: transparent;
                    }
                }
            }
    
            .icono-vida {
                stroke: $grey-300;
                fill: transparent
            }
        }

        .modelo-button-title {
            color: $grey-400;
        }
    }

    &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        border-radius: 5px;
        border: 1px solid black;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &-title {
        margin-top: 7px;
        font-size: 12px;
        max-width: 12ch;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.nuevo-modelo-button {
    border: 0;
    border-radius: 12px;
    color: #ffffff;
    background: $blue-500;
    padding: 11px 24px;
    font-weight: 800;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover {
        background: $blue-700;
    }
}