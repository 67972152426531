@import '../../styles/colors.scss';

.pantalla-bienvenida-modal {
    background-color: #ffffff;
    width: 381px;
    padding: 32px 34px;
    border-radius: 12px;
    color: $grey-900;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 90vh;
    overflow-y: auto;

    &-button {
        color: $grey-400;
    }

    h1 {
        font-size: 22px;
        font-weight: 800;
        margin: 0;
    }

    &-items {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-item {
            display: flex;
            flex-direction: column;
            gap: 16px;

            h3 {
                font-size: 14px;
                margin: 0;
                font-weight: 800;
            }

            input {
                width: 100%;
                height: 40px;
                padding: 4px 8px;
                border-radius: 8px;
                border: 0.75px solid $grey-200;
                font-size: 14px;

                &:focus {
                    outline: 1px solid $blue-500;
                }

                &::placeholder {
                    font-size: 14px;
                    font-weight: 600;
                    color: $grey-400;
                }
            }
        }
    }

    &-actions {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: flex-end;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
    }
    
    .MuiAutocomplete-endAdornment {
        top: 11px;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
    }
}

#dropdown-fotosLibres {
    border: 0.75px solid $grey-200;
    border-radius: 8px;
    padding: 5px 0 0 16px !important;
    
    &:focus {
        outline: none;
    }
}

.archivo-subido {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
        display: flex;
        align-items: center;
        gap: 4px;

        p {
            margin: 0;
            font-size: 16px;
            font-weight: 800;
            color: $grey-500;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.pantalla-bienvenida-modal-politicas {
    width: 900px;
}