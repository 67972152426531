@import '../../styles/colors.scss';

.error-card {
    padding: 8px;
    border-radius: 4px;

    &-header {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 4px;

        &-title {
            font-size: 16px;
            font-weight: 800;
            margin: 0 !important;
        }

        &-icon {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-text, &-items {
        padding-left: 28px;
    }

    &-text {
        font-size: 16px;
        margin-bottom: 4px;
    }

    &-expand {
        padding-left: 28px;
        color: $blue-500;
        font-size: 16px;
        font-weight: 600;
        margin-top: 16px;
        cursor: pointer;
    }
}