@import '../../styles/colors.scss';

.drop-area {
    width: 100%;
}

.drag-area {
    padding: 16px;
    border: dashed 1px $grey-300;
    display: flex                           ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
        color: $grey-300;
    }

    p {
        color: $grey-300;
        font-weight: 800;
        font-size: 14px;
        margin: 0;
    }
}