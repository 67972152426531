@import '../../styles/colors.scss';

.nota-agente {
    &-header {
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
            color: $grey-500;
            width: 16px;
            height: 16px;
        }

        p {
            color: $grey-300;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
        }
    }

    &-texto {
        font-size: 14px;
        font-weight: 600px;
    }
}