@import '../../styles/colors.scss';

.inspecciones {
    padding-top: 24px;

    h3 {
        font-weight: 800;
        font-size: 26px;
    }
}

.filter-label {
    color: $grey-500;
    font-size: 14px;
}

input#clientes-filter-combo-box {
    font-family: 'Nunito sans';
    padding-left: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $grey-900;
}

.MuiOutlinedInput-root {
    padding: 1px !important;
    border-radius: 12px !important;
    height: 40px;

    &:focus {
        outline: none;
        border: 0;
    }
}

.data-grid-bold-cell {
    font-weight: 800;
}

.MuiAutocomplete-option {
    font-family: 'Nunito sans' !important;
    color: $grey-500;
    font-size: 14px;

    &:hover {
        font-weight: 800;
    }

    &:active {
        color: $blue-500;
    }
}

.MuiAutocomplete-endAdornment {
    margin-right: 6px;
    margin-top: 2px;
}

.MuiAutocomplete-hasPopupIcon {
    svg {
        color: $grey-400;
        width: 20px;
        height: 20px;
        stroke-width: 3px;
    }
}

.deshacer {
    font-weight: 800;
    text-decoration: underline;
    cursor: pointer;
}