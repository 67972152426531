@import '../../styles/colors.scss';

.lista-adjuntos {
    background-color: $grey-50;
    border-radius: 12px;
    width: 100%;
    padding: 24px;
    height: max-content;

    &-main {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 219px;
        overflow: auto;
    }

    &-header {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: $grey-500;
    }

    &-actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 16.5px;
    }

    &-modal {
        background-color: #ffffff;
        width: 568px;
        padding: 48px;
        border-radius: 12px;
        color: $grey-900;
        display: flex;
        flex-direction: column;
        gap: 32px;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 800;
        }

        p {
            margin: 0;
            font-size: 14px;
        }

        &-actions {
            display: flex;
            gap: 16px;
            justify-content: flex-end;
        }
    }
}

.modal-file-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal-file {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
            width: 20px;
            height: 20px;
        }
    
        p {
            margin: 0;
            font-size: 16px;
            font-weight: 800;
            color: $grey-500;
        }
    }

    &-actions {
        display: flex;
        gap: 4px;

        svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        &-edit {
            color: $blue-500
        }

        &-delete {
            color: $error;
        }
    }
}