@import '../../styles/colors.scss';

.dropdown-custom {
    background-color: $blue-50;
    border: 0;
    height: 28px !important;
    border-radius: 8px !important;
    padding: 4px 8px;

    .MuiSelect-select {
        font-weight: 800;
        color: $blue-500;
        font-size: 12px;
        padding-right: 0 !important;
        padding-top: 18px;
    }

    svg {
        margin: 0;
        width: 40px;
        color: $blue-500;
    }

    fieldset {
        border: 0;
        height: 28px;
        padding-top: 14px;
    }


    .MuiList-root-MuiMenu-list {
        background-color: red;
    }

    .MuiList-root {
        background-color: red;

        & .MuiMenuItem-root {
            font-size: 12px !important;
        }
    }
}

.MuiList-root {
    max-height: 240px;
    min-width: 145px;

    & .MuiMenuItem-root {
        font-size: 12px !important;
    }
}